import { Injectable } from '@zhfe/vue2-di-plugin'
import zhfeCollect from '@zhfe/zhfe-collect'
import { CookieService } from './cookie.service'
import { getUrlParam } from '@shared/utils/base'

/** 埋点版本 */
const version = 1

@Injectable()
export class ReportService {
  constructor (private cookie: CookieService) {}

  webReportUrl = 'https://helpinfockeniu1.ksmobile.com/g/v1/'

  pubilc_filed = {
    uuid: '',
    tid1: 0,
    tid2: 0,
    tod1: 0,
    tod2: 0,
    type: 0,
    tryno: 0,
    iid: 0,
    collect_time: 0,
    lastver: 0,
    svrid: '',
    wtod2: '',
    installtime: '',
    osver: '',
    unionsvrid: '',
    product_no: 557,
    public_index: 1,
    version,
    nfrom: getUrlParam('nfrom') || 0,
    accountid: this.cookie.uuid
  }

  /** 报表名称集合 */
  table = {
    // 可牛官网展示点击下载
    infockeniu_website_act: 'infockeniu_website_act:186 act:int nfrom:short csource:int download:int click_tab:int click_product:int click_banner:int click_hot:int version:short accountid:string',
    // 可牛校园行网页活动埋点
    infockeniu_activity_page: 'infockeniu_activity_page:178 nshow:byte nfrom:short click:short version:short'
  }

  /**
   * 网页上报
   */
  webReport (baseObj = {}, dat = '') {
    return (data = {}) => {
      zhfeCollect(this.webReportUrl, { ...this.pubilc_filed, ...baseObj }, dat).report(data)
    }
  }
}
