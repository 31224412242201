<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useRootReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { appInjector } from '@/app-injector'
import { HttpInterceptorService } from './service/http-interceptor.service'
import { Http, HttpConfig, HttpInterceptor } from '@zhfe/common-library'

export default defineComponent({
  name: 'App',
  setup () {
    const injector = useRootReflectiveInjector([
      Http,
      {
        provide: HttpConfig,
        useValue: {
          baseURL: 'https://api-pcmgrvip.keniu.com',
          timeout: 5000
        },
      },
      {
        provide: HttpInterceptor,
        useClass: HttpInterceptorService,
      },
    ])
    // 这里特殊性况，因 vue2 composition api 不能在最外层设置注入器，所以手动扩展一层注入器
    injector.parentInjector = appInjector
  }
})

</script>
<style lang="scss">
body {
    font-family: Microsoft Yahei, PingFang SC, Lantinghei Sc, Source Han Sans, Helvetica neue, Arial,
    sans-serif !important;
}
</style>
