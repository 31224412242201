import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCompositionApi from '@vue/composition-api'
import '@shared/styles/reset/index.css'

Vue.config.productionTip = false
Vue.use(VueCompositionApi)

new Vue({
  router,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
