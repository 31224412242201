<template>
  <footer class="footer">
    <ul>
      <li
        v-for="iten in viewModel.footerList"
        :key="iten.id"
      >
        <p class="title">
          {{ iten.title }}
        </p>
        <a
          v-for="citem in iten.children"
          :key="citem.id"
          :href="citem.url"
          :target="iten.id !== 2 ? '_blank' : '_self'"
        >
          {{ citem.title }}</a>
      </li>
    </ul>
    <div class="fcopyright">
      <p>联系电话：0756-3327084  |  联系邮箱：keniu_contact@conew.com</p>
      <p>
        www.keniu.com 2021 @ All rights Reserved &nbsp;<span class="police-icon" />
        <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案号：琼ICP备19001658号</a>
      </p>
      <p>{{ viewModel.filing }}</p>
    </div>
  </footer>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { FooterService } from '@/layout/footer.service'

export default defineComponent({
  name: 'Footer',

  setup (props) {
    const injector = useReflectiveInjector([FooterService])
    const service = injector.get(FooterService)
    const viewModel = reactive({
      footerList: service.footerList,
      copyright: `www.keniu.com.com ${new Date().getFullYear()} @ All rights Reserved  网站备案号：琼ICP备19001658号-2`,
      filing: '海南驰豹科技有限公司  |  海南省老城高新技术产业示范区海南生态软件园A17幢一层1001 '
    })
    return {
      viewModel
    }
  }
})
</script>

<style scoped lang="scss">
.footer {
  height: 224px;
  background: #1f1e1e;
  color: #999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    li {
      text-align: center;
      &:nth-child(1){
        border-right: 2px solid #3f3f3f;
        margin-right:60px;
        padding-right: 60px;
      }
      &:nth-child(3){
        border-left: 2px solid #3f3f3f;
         margin-left:60px;
        padding-left: 60px;
      }
      .title {
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 16px;
        font-size: 16px;
      }
      a {
        margin-left: 24px;
        cursor: pointer;
        color: #999999;
        text-decoration: none;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .fcopyright {
    margin-top: 48px;
    font-size: 12px;
    text-align: center;
    a {
      color: #999999;
      text-decoration: none;
    }
    p{
      padding-bottom: 10px;
    }
    .police-icon{
        background: url('./_images/footer.png') no-repeat ;
        height: 16px;
        width: 16px;
        display: inline-block;
        vertical-align: text-top;
    }
  }
}
</style>
