import { Injectable } from '@zhfe/vue2-di-plugin'
import cookie from 'js-cookie'
import { getUuid } from '@shared/utils/base'

@Injectable()
export class CookieService {
  get uuid () {
    const uuidkey = 'uuid'
    let uid = cookie.get(uuidkey)

    if (!uid) {
      uid = getUuid()
      cookie.set(uuidkey, uid, { expires: 365, domain: 'keniu.com' })
    }

    return uid
  }
}
