<template>
  <div
    class="navbar_container flex_center"
    :class="{ active_head: viewModel.scrollSel }"
  >
    <div class="navbar">
      <img
        src="./_images/logo.png"
        class="logo"
        @click="clickLogo"
      >

      <ul class="inner">
        <li
          v-for="item in viewModel.navList"
          :key="item.id"
          :class="{ active: activeMenu === item.routePath }"
          class="nav-item"
          @click="clickNav(item, $event)"
          @mouseenter="hoverEnterProduct(item)"
          @mouseleave="hoverLeaveProduct(item)"
        >
          <a :href="item.url">{{ item.name }}</a>
          <span
            v-if="item.hot"
            class="hot_bubble"
          >火热校园行</span>
          <transition name="fade">
            <ul
              v-if="item.children && !item.isDropdown && viewModel.isDropdown && viewModel.navId === item.id"
              class="product_ul flex_start_center"
              :class="`product_${item.id}`"
            >
              <li
                v-for="(cItem, cIdx) in item.children"
                :key="cIdx"
                class="flex_start_center"
                @click.stop="clickSubItem(item, cItem)"
              >
                <img
                  v-if="cItem.icon"
                  :src="require(`../views/home/_images/${cItem.icon}.png`)"
                  alt=""
                >
                <div class="position_desc">
                  <div class="features_title">
                    {{ cItem.title }}
                  </div>
                  <span class="features_text">{{ cItem.text }}</span>
                </div>
              </li>
            </ul>
            <ul
              v-else-if="item.children && item.isDropdown && viewModel.isDropdown && viewModel.navId === item.id"
              class="sub-nav"
            >
              <li
                v-for="citem in item.children"
                :key="citem.id"
                :class="['head_li flex_start_center', { active: activeMenu === citem.routePath }]"
                @click.stop="clickItem(citem)"
              >
                <i :class="citem.iconName" />
                <span>{{ citem.name }}</span>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, onMounted } from '@vue/composition-api'
import { NavbarService } from './navbar.service'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
export default defineComponent({
  name: 'Navbar',

  setup (props, context) {
    const injector = useReflectiveInjector([NavbarService])
    const service = injector.get(NavbarService)

    const viewModel = reactive({
      navList: service.navList,
      scrollSel: false,
      isDropdown: false,
      navId: 0
    })

    const activeMenu = computed(() => context.root.$route.path)

    onMounted(() => {
      document.onscroll = function () {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        viewModel.scrollSel = scrollTop > 70
      }
    })

    function clickNav (item, $event) {
      // 点击空白区域不跳转
      if ($event.target.tagName.toLowerCase() === 'ul') {
        return
      }

      const path = item.routePath
      if (!path) {
        return
      }

      service.report({
        act: '2',
        click_tab: item.report
      })

      if (item.type === 'local') {
        context.root.$router.push({ path })
      } else {
        location.href = path
      }
    }

    function clickItem (item) {
      const { url, newWindow = false, click_product } = item

      service.report({
        act: '2',
        click_product
      })
      if (newWindow) {
        window.open(url)
      } else {
        location.href = url
      }
    }

    function clickSubItem (pItem, cItem) {
      const { routePath } = pItem
      const { report } = cItem

      service.report({
        act: '2',
        click_tab: report
      })

      location.href = routePath
    }

    function clickLogo () {
      context.root.$router.push('/')
    }

    function hoverEnterProduct (item) {
      if (item.children) {
        viewModel.isDropdown = true
        viewModel.navId = item.id

        if (item.isDropdown) {
          service.report({
            act: '2',
            click_tab: item.report
          })
        }
      }
    }

    function hoverLeaveProduct (item) {
      if (item.children) {
        viewModel.isDropdown = false
      }
    }

    return {
      viewModel,
      activeMenu,
      clickNav,
      clickSubItem,
      clickItem,
      clickLogo,
      hoverEnterProduct,
      hoverLeaveProduct
    }
  }
})
</script>

<style scoped lang="scss">
$navbar-height: 70px;
.navbar_container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  transition: all .2s linear;
  z-index: 1;
  &:hover {
    background: rgba(0, 0, 0, 0.800000011920929);
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.10000000149011612);
  }
}
.navbar {
  color: #edebeb;
  height: $navbar-height;
  position: relative;
  width: 1222px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  .logo{
    cursor: pointer;
    width: 158px;
    height: 38px;
  }
  .inner {
    display: flex;
    .nav-item {
      position: relative;
      margin-left: 50px;
      height: $navbar-height;
      line-height: $navbar-height;
      cursor: pointer;
      flex-shrink: 0;
      .hot_bubble {
        position: absolute;
        @include wh(60px, 16px);
        font-size: 12px;
        color: #FFFFFF;
        background: #FF0000;
        border-radius: 7px 7px 7px 0px;
        left: 48px;
        top: 4px;
        line-height: 1.2;
        padding: 2px 4px;
      }
      &:first-of-type {
        margin-left: 0;
      }
      & > a {
        color: #fff;
        opacity: 0.7;
      }
      &.active {
        & > a {
          color: #fff;
          opacity: 1;
        }
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background: #fff;
          border-radius: 2px;
          position: absolute;
          bottom: 7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:hover {
        & > a {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }

  .sub-nav {
    position: absolute;
    top: 70px;
    left: -36px;
    width: 220px;
    background: #ffffff;
    padding: 12px;
    box-shadow: 0px 1px 12px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 6px 6px;
    box-sizing: border-box;
    li {
      height: 48px;
      line-height: 48px;
      margin-top: 8px;
      border-radius: 4px;
      font-size: 16px;
      color: #666666;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        background: #F5F5F5;
        box-shadow: -1px -1px 0px 0px #F6F6F6;
        border-radius: 6px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
.active_head {
  background: rgba(0, 0, 0, 0.800000011920929);
  box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.10000000149011612);
  z-index: 2;
}
.head_li {
  position: relative;
}
.common_icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 12px 0 20px;
}
.keniu_pdf_icon {
  @extend .common_icon;
  background: url('~./_images/keniu_pdf_icon.png');
  background-size: cover;
}
.keniu_pic_icon {
  @extend .common_icon;
  background: url('~./_images/keniu_pic_icon.png');
  background-size: cover;
}
.keniu_bg_icon {
  background: url('~./_images/keniu_bg_icon.png');
  background-size: cover;
  @extend .common_icon;
}
.keniu_zs_icon {
  background: url('~./_images/keniu_zs_icon.png');
  background-size: cover;
  @extend .common_icon;
}
.keniu_ys_icon {
  @extend .common_icon;
  background: url('~./_images/keniu_ys_icon.png');
  background-size: cover;
}
.keniu_file_icon {
  @extend .common_icon;
  background: url('~./_images/keniu_file_icon.png');
  background-size: cover;
}
.keniu_yx_icon {
  @extend .common_icon;
  background: url('~./_images/keniu_yx_icon.png');
  background-size: cover;
}
.product_ul {
  width: 1050px;
  position: absolute;
  top: 70px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 1px 12px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 6px 6px;
}
.product_2 {
  left: -480px;
}
.product_3 {
  left: -592px;
}
.product_4 {
  left: -738px;
}
.product_5 {
  left: -852px;
}
.product_ul li {
  @include wh(350px, 160px);
  padding: 47px 25px 47px 30px;
  box-sizing: border-box;
}
.product_ul li:hover {
  background: #F5F5F5;
}

.product_ul img {
  @include wh(50px, 50px);
  margin-right: 20px;
}
.position_desc {
  position: relative;
  height: 66px;
}
.features_title {
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  font-weight: 600;
  color: #0B1D30;
  margin-top: 8px;
}
.features_text {
  display: block;
  margin-top: 4px;
  line-height: 20px;
  height: 20px;
  font-size: 14px;
  color: #999999;
}
</style>
