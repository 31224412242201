import { Injectable } from '@zhfe/vue2-di-plugin'

enum moreFunction {
  icon = 'compress-more',
  title = '更多接入中',
  text = '更多高级功能正在接入中...'
}
@Injectable()
export class ConfigService {
  downloadUrl = {
    pdf: 'https://software-down.keniu.com/knsoft/Keniu_pdf_Setup.exe',
    picture: 'https://software-down.keniu.com/knsoft/Keniu_pic_Setup.exe',
    computer: 'https://software-down.keniu.com/knsoft/keniucleaner_600_1.exe',
    compress: 'https://software-down.keniu.com/knsoft/Keniu_yasuo_Setup.exe',
    file: 'https://software-down.keniu.com/knsoft/Keniu-FileManager.exe'
  }

  /** 下载文件 */
  downloadFile = (path) => {
    if (path) {
      const a = document.createElement('a')
      a.setAttribute('download', '')
      a.setAttribute('href', path)
      a.click()
    }
  }

  /** 热门产品对应描述 */
  featuresMap = {
    picture: [
      {
        icon: 'picture-print',
        title: '高级打印支持',
        text: '自由排版，多图批量打印，强化文字效果',
        report: 31
      },
      {
        icon: 'picture-img-to-pdf',
        title: '图片转PDF',
        text: '支持一键多图片合成为一个PDF文件，快捷方便，画质无损',
        report: 32
      },
      {
        icon: 'picture-compression',
        title: '图片压缩',
        text: '自定义压缩图片至指定大小范围',
        report: 33
      },
      {
        icon: 'picture-oblique',
        title: '修改尺寸',
        text: '自定义修改图片支持至指定大小范围，适用多种场景',
        report: 34
      },
      {
        icon: 'picture-ocr',
        title: '文字识别',
        text: '识别图片中的文字，支持识别结果转为word/exce/txt',
        report: 35
      },
      {
        icon: 'picture-beautify',
        title: '图片美化',
        text: '支持对图片进行裁剪、旋转、涂抹、色彩调整、添加水印等操作',
        report: 36
      },
    ],
    pdf: [
      {
        icon: 'pdf-read',
        title: 'PDF阅读管理',
        text: '界面简洁，操作简易，百M文档秒开，保证您的极速阅读管理',
        report: 11
      },
      {
        icon: 'pdf-transition',
        title: 'PDF格式转换',
        text: '免费实现PDF格式转换，支持一键批量转换，省心又省力',
        report: 12
      },
      {
        icon: 'pdf-print',
        title: '高级打印支持',
        text: '快速完成PDF页面高级打印，助你工作更高效',
        report: 13
      },
      {
        icon: 'pdf-transition',
        title: '图片、PDF互转',
        text: '支持PDF与图片互转，轻轻一点闪电转换，享受高效办公',
        report: 14
      },
      {
        icon: 'pdf-annotation',
        title: '注释',
        text: '简单操作即可添加书签、注释和文本高亮，重点内容不错过',
        report: 15
      },
      {
        icon: 'pdf-encryption',
        title: '文件拆分加密',
        text: '支持PDF文件进行有序合并与拆分，还可一键加密，保护您的PDF文档',
        report: 16
      },
    ],
    computer: [
      {
        icon: 'computer-pop-ups-intercept',
        title: '拦截弹窗',
        text: '自动拦截恶意弹窗行为，净化电脑环境不受打扰',
        report: 21
      },
      {
        icon: 'computer-cleaner',
        title: '垃圾清理',
        text: '深度清理系统和软件残留垃圾，释放空间',
        report: 22
      },
      {
        icon: 'computer-uninstall',
        title: '软件卸载',
        text: '卸载问题软件，防止电脑出现乱弹广告、卡慢等问题',
        report: 23
      },
      {
        icon: 'computer-c-drive-slimming',
        title: '系统盘瘦身',
        text: '转移虚拟内存，大文件搬家，释放C盘空间，提升电脑速度',
        report: 25
      },
      {
        icon: 'computer-privacy-cleanup',
        title: '隐私清理',
        text: '清理隐私痕迹，保护个人隐私 不泄露',
        report: 26
      },
      {
        icon: 'computer-debris-cleaning',
        title: '碎片清理',
        text: '清理磁盘碎片，可以提高电脑的硬盘使用寿命，提升电脑运行速度',
        report: 27
      },
      {
        icon: 'computer-opt',
        title: '一键优化',
        text: '一键智能优化系统，专业解决电脑问题，提升电脑效率',
        report: 28,
      },
      {
        icon: moreFunction.icon,
        title: moreFunction.title,
        text: moreFunction.text,
        report: 29
      },
      {}
    ],
    compress: [
      {
        icon: 'compress-format',
        title: '支持丰富压缩格式',
        text: '格式支持超全的压缩软件，稳定安全',
        report: 51
      },
      {
        icon: 'compress-unzip',
        title: '高效解压缩',
        text: '解压1G文件最快只要5秒',
        report: 52
      },
      {
        icon: 'compress-mind',
        title: '智能解压',
        text: '自动识别压缩包结构，智能解压文件',
        report: 53
      },
      {
        icon: 'compress-preview',
        title: '文件预览',
        text: '支持压缩包内文件预览，办公更高效',
        report: 54
      },
      {
        icon: 'compress-compatible',
        title: '兼容性强',
        text: '支持Win11，Win10，Win8，Win7，Vista在内的Windows系统',
        report: 55
      },
      {
        icon: moreFunction.icon,
        title: moreFunction.title,
        text: moreFunction.text,
        report: 56
      },
    ],
    file: [
      {
        icon: 'file-more-tab',
        title: '窗口多标签管理',
        text: '告别凌乱的窗口，助您高效办公',
        report: 41
      },
      {
        icon: 'file-high',
        title: '书签自由定义',
        text: '常用工具、文件、文件夹均可一键即达',
        report: 42
      },
      {
        icon: 'file-search',
        title: '极速搜索',
        text: '全盘搜索，结果秒出，比系统搜索更高效',
        report: 43
      },
      {
        icon: 'file-ui',
        title: '全新UI风格',
        text: '界面简洁明了，轻巧好用',
        report: 44
      },
      {
        icon: 'compress-compatible',
        title: '兼容性强',
        text: '支持Win10、Win8、Win7、Vista和WinXP在内的所有Windows系统',
        report: 45
      },
      {
        icon: moreFunction.icon,
        title: moreFunction.title,
        text: moreFunction.text,
        report: 46
      }
    ],
    bg: [
      {
        icon: 'bg_one',
        title: 'PPT精品模板',
        text: '海量总结汇报\\教学课件等ppt模板',
      },
      {
        icon: 'bg_two',
        title: '简历模板',
        text: '精品求职简历模板，3分钟写好简历',
      },
      {
        icon: 'bg_three',
        title: 'Excel模板',
        text: '海量场景报表模板，工作事半功倍',
      },
      {
        icon: 'bg_four',
        title: '图片素材',
        text: '海量免抠素材\\背景图片\\设计成品模板',
      },
      {
        icon: 'bg_five',
        title: '版权保障',
        text: '100%原创内容 版权无忧',
      },
      {
        icon: 'bg_six',
        title: '个人可商用',
        text: '支持个人名义商业使用',
      },
    ]
  }
}
