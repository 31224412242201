import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../layout/index.vue'

Vue.use(VueRouter)

/** 解决vue路由跳转报错问题 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes: Array<RouteConfig> = [

  {
    path: '/',
    component: Layout,
    children: [{
      path: '/',
      name: 'home',
      component: () => import('../views/home/home.vue'),
    }]
  },
  {
    path: '/products',
    component: Layout,
    children: [{
      path: '/products',
      name: 'products',
      component: () => import('../views/products/products.vue'),
    }]
  },
  {
    path: '/super-member',
    component: Layout,
    children: [{
      path: '/super-member',
      name: 'super-member',
      component: () => import('../views/activity/super-member.vue'),
    }]
  },
  {
    path: '/about-us',
    component: Layout,
    children: [{
      path: '/about-us',
      name: 'about-us',
      component: () => import('../views/about-us/about-us.vue'),
    }]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
