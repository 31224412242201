















import Footer from './Footer.vue'
import Navbar from './Navbar.vue'
// import { NavbarService } from './layout.service'
import { defineComponent } from '@vue/composition-api'
// import { useRootReflectiveInjector } from '@zhfe/vue2-di-plugin'

export default defineComponent({
  name: 'AppMain',
  components: { Footer, Navbar },

  setup (props, context) {
    // useRootReflectiveInjector([NavbarService])
    return {
    }
  }
})
