import { Injectable } from '@zhfe/vue2-di-plugin'
import { ReportService } from '@/service/report.service'
import { ConfigService } from '@/service/config.service'

@Injectable()
export class NavbarService {
  constructor (
    private reporter: ReportService,
    private config: ConfigService
  ) {
  }

  navList = [
    { id: 1, name: '首页', routePath: '/', report: 1, type: 'local' },
    { id: 2, name: '可牛PDF', routePath: 'https://pdf.keniu.com/', report: 10, children: this.config.featuresMap.pdf, type: 'url' },
    { id: 3, name: '可牛清理大师', routePath: 'https://zs.keniu.com/', report: 20, children: this.config.featuresMap.computer, type: 'url' },
    { id: 4, name: '可牛看图', routePath: 'https://pic.keniu.com/', report: 30, children: this.config.featuresMap.picture, type: 'url' },
    { id: 5, name: '可牛压缩', routePath: 'https://ys.keniu.com/', report: 50, children: this.config.featuresMap.compress, type: 'url' },
    {
      id: 6,
      name: '更多产品',
      children: [
        { id: 4, name: '可牛文件管理', url: 'https://file.keniu.com/', iconName: 'keniu_file_icon', click_product: 7 },
        // { id: 6, name: '可牛影像', url: 'https://yx.keniu.com', iconName: 'keniu_yx_icon', click_product: 6 },
        { id: 5, name: '可牛办公模板', url: 'https://o.keniu.com', iconName: 'keniu_bg_icon', click_product: 5, newWindow: true },
      ],
      isDropdown: true,
      report: 3
    },
    // { id: 7, name: '超级会员', routePath: '/super-member', report: 6, type: 'local', hot: true },
    { id: 8, name: '关于我们', routePath: '/about-us', report: 2, type: 'local' }
  ]

  report = this.reporter.webReport({
    act: 0,
    click_tab: 0,
    click_banner: 0,
    click_hot: 0,
    download: 0,
    csource: 1,
    click_product: 0
  }, this.reporter.table.infockeniu_website_act)
}
