import { NullInjector, ReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { ReportService } from './service/report.service'
import { ConfigService } from '@/service/config.service'
import { CookieService } from '@/service/cookie.service'

export const appInjector = new ReflectiveInjector(new NullInjector(), [
  ConfigService,
  CookieService,
  ReportService,
])
