import { Injectable } from '@zhfe/vue2-di-plugin'

@Injectable()
export class FooterService {
  /** 尾部内容 */
  footerList = [
    // {
    //   title: '产品列表',
    //   id: 1,
    //   children: [
    //     { id: 1, title: '可牛电脑管家', url: '' },
    //     { id: 2, title: '可牛看图', url: '' },
    //     { id: 3, title: '可牛PDF', url: '' },
    //     { id: 4, title: '可牛压缩', url: '' },
    //   ]
    // },
    {
      title: '合作联系',
      id: 2,
      children: [
        { id: 1, title: '关于我们', url: '/about-us' },
        { id: 2, title: '联系我们', url: '/about-us' },
      ]
    },
    {
      title: '友情链接',
      id: 3,
      children: [
        { id: 1, title: '百度', url: 'https://www.baidu.com/' },
        { id: 2, title: '腾讯QQ', url: 'https://www.qq.com/' }
      ]
    }
  ]
}
