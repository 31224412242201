// @ts-nocheck
import axiosJsonpAdapter from '../../ajax/axios-jsonp'

/**
 * 返回一个uuid
 * @returns {String} uuid字符串
 */
export const getUuid = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  let i
  // eslint-disable-next-line no-magic-numbers
  for (i = 0; i < 36; i++) {
    // eslint-disable-next-line no-magic-numbers
    s[i] = hexDigits.substr(Math.floor(Math.random() * 16), 1)
  }
  // eslint-disable-next-line no-magic-numbers
  s[14] = '4'
  // eslint-disable-next-line no-magic-numbers
  // @ts-ignore
  // eslint-disable-next-line no-magic-numbers
  s[19] = hexDigits.substr((s[19] & 3) | 8, 1)
  // eslint-disable-next-line no-magic-numbers
  return s.join('').substr(0, 32)
}

/**
 * 返回用户svrid，通过调用毒霸&精灵等客户端开放的web接口
 * @returns {Promise} svrid字符串
 */
export const getSvrid = () => {
  const isHttp = window.location.protocol === 'http:'
  const port = isHttp ? '8890' : '8990'
  const url = `//localsupport.ijinshan.com:${port}/uuid`

  return axiosJsonpAdapter({
    url: url,
    timeout: 1000
  }).then(res => {
    return res.data.svrid
  }).catch(() => {
    return Promise.resolve(getUuid())
  })
}
